import IconSpeed from '@/assets/icons/icon-speed.svg?react';
import { Slider } from '@/components/ui/slider';
import { cn } from '@listening/shared';
import { useState } from 'react';

const speedOptions: string[] = [
  '0.75',
  '1.0',
  '1.10',
  '1.15',
  '1.25',
  '1.5',
  '1.75',
  '1.85',
  '2.5',
];

export default function SpeedControlReact() {
  const defaultSpeedIndex = speedOptions.indexOf('1.25');
  const [selectedSpeed, setSelectedSpeed] = useState('1.25');
  const [sliderValue, setSliderValue] = useState([
    (defaultSpeedIndex / (speedOptions.length - 1)) * 100,
  ]);

  const handleSpeedClick = (speed: string): void => {
    setSelectedSpeed(speed);
    // Calculate slider position based on speed
    const index = speedOptions.indexOf(speed);
    const newSliderValue = [(index / (speedOptions.length - 1)) * 100];
    setSliderValue(newSliderValue);
  };

  const handleSliderChange = (value: number[]): void => {
    if (value[0] === undefined) {
      return;
    }
    // Calculate speed based on slider position
    const index = Math.round((value[0] / 100) * (speedOptions.length - 1));
    const newSpeed = speedOptions[index];
    if (newSpeed) {
      setSelectedSpeed(newSpeed);
      setSliderValue(value);
    }
  };

  return (
    <div
      className="flex-1 rounded-2xl bg-[rgba(255,255,255,0.05)] p-6"
      role="region"
      aria-label="Playback speed control"
    >
      <div className="mb-6 flex items-center gap-3">
        <IconSpeed aria-hidden="true" />
        <span className="text-xl font-semibold">Speed</span>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <button
            type="button"
            className="flex size-10 items-center justify-center text-[#03314B] hover:opacity-80"
            onClick={() => {
              const currentIndex = speedOptions.indexOf(selectedSpeed);
              if (currentIndex > 0) {
                const newSpeed = speedOptions[currentIndex - 1];
                if (newSpeed) {
                  handleSpeedClick(newSpeed);
                }
              }
            }}
            aria-label="Decrease speed"
          >
            <span className="text-3xl font-medium" aria-hidden="true">
              −
            </span>
          </button>

          <div className="relative flex-1">
            <Slider
              value={sliderValue}
              onValueChange={handleSliderChange}
              max={100}
              step={1}
              className="w-full"
              aria-label="Speed control slider"
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={sliderValue[0]}
              trackClassName="bg-white/50 cursor-pointer"
              thumbClassName="border-[#0587FB] bg-[#0587FB] cursor-pointer"
              rangeClassName="bg-[#0587FB]"
              rangeComponent={
                <div className="size-full cursor-pointer rounded-r-full bg-[#0587FB]" />
              }
            />
          </div>

          <button
            type="button"
            className="flex size-10 items-center justify-center text-[#03314B] hover:opacity-80"
            onClick={() => {
              const currentIndex = speedOptions.indexOf(selectedSpeed);
              if (currentIndex < speedOptions.length - 1) {
                const newSpeed = speedOptions[currentIndex + 1];
                if (newSpeed) {
                  handleSpeedClick(newSpeed);
                }
              }
            }}
            aria-label="Increase speed"
          >
            <span className="text-3xl font-medium" aria-hidden="true">
              +
            </span>
          </button>
        </div>

        <div
          className="grid grid-cols-3 gap-3"
          role="radiogroup"
          aria-label="Speed presets"
        >
          {speedOptions.map((speed) => (
            <button
              key={speed}
              type="button"
              className={cn(
                'rounded-lg px-4 py-3 text-base font-medium transition-colors lg:px-3 lg:py-2 lg:text-sm xl:px-6 xl:py-4 xl:text-lg',
                'text-[#03314B]',
                {
                  'bg-[rgba(6,135,251,0.15)]': speed === selectedSpeed,
                  'hover:bg-white/10': speed !== selectedSpeed,
                },
              )}
              onClick={() => {
                handleSpeedClick(speed);
              }}
              aria-label={`Set speed to ${speed}x`}
              aria-pressed={speed === selectedSpeed}
            >
              {speed}x
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
